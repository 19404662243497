import React, { useMemo } from 'react';
import { FormControl, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Variant } from '@mui/material/styles/createTypography';

interface Props {
  label: string;
  fullWidth?: boolean;
  variant?: Variant;
  isUnderline?: boolean;
  className?: string;
}

const FormSubtitle: React.FC<Props> = ({
  label,
  fullWidth = true,
  variant = 'h5',
  isUnderline = true,
  className,
}) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <FormControl
      variant="outlined"
      fullWidth={fullWidth}
      id={id}
      margin="normal"
      className={className}
    >
      <Typography variant={variant} className={isUnderline ? 'underline' : ''}>
        {label}
      </Typography>
    </FormControl>
  );
};
export default FormSubtitle;
