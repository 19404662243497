import React, { useMemo } from 'react';
import useProperties from '../../hooks/useProperties';
import SelectValueInterface from '../../interfaces/selectValue';
import FormAutoFill from '../formComponents/formAutoFill';

interface Props {
  onChange: (propertyID: string) => void;
  val: string;
  label: string;
  showEmpty?: boolean;
  emptyLabel?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
}

const PropertySelector: React.FC<Props> = (props) => {
  const {
    onChange,
    val,
    disabled = false,
    showEmpty,
    emptyLabel,
    label,
    fullWidth = true,
    className,
  } = props;
  const properties = useProperties({ isActive: true })[0];
  const values: SelectValueInterface[] = useMemo(() => {
    const l: SelectValueInterface[] = [];
    Object.keys(properties).forEach((key) => {
      const property = properties[key];
      const fullAddress =
        (property?.address?.flat || '') +
        ', ' +
        (property?.address?.postCode || '');
      l.push({ value: key, label: fullAddress, key: property._id });
    });
    l.sort((a, b) => a.label.localeCompare(b.label));
    if (showEmpty) {
      l.unshift({ value: '', label: emptyLabel || '', key: 'empty' });
    }

    return l;
  }, [properties, showEmpty, emptyLabel]);

  return (
    <FormAutoFill
      disabled={disabled}
      val={val}
      values={values}
      onChange={onChange}
      label={label}
      fullWidth={fullWidth}
      className={className}
      showClear
    />
  );
};
export default PropertySelector;
