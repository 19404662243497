export function filterBySubscriptionFilter(
  subscriptionFilter: string | null | undefined,
) {
  return (val: { packageID?: string | null | undefined }): boolean => {
    switch (subscriptionFilter) {
      case undefined:
      case null:
      case '':
      case 'Any':
        return true;
      case 'NoSubscriptions':
        return !val.packageID || val.packageID === 'none';
      case 'AllSubscription':
        return Boolean(val.packageID) && val.packageID !== 'none';
      default:
        return val.packageID === subscriptionFilter;
    }
  };
}
