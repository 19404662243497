import FormDateBase, { FormBaseDateProps } from './formDateBase';

const FormDateTime: React.FC<Partial<FormBaseDateProps>> = ({
  format,
  ...otherProps
}) => {
  const props = {
    showTime: true,
    format: format || 'HH:mm, DD MMMM, yyyy',
    ...otherProps,
  };
  return <FormDateBase {...props} />;
};
export default FormDateTime;
