import {
  AGENCIES_COLLECTION,
  DRAFT_CONTRACTORS_COLLECTION,
  FILES_COLLECTION,
  INVITATIONS_COLLECTION,
  NOTIFICATION_COLLECTION,
  PACKAGES_COLLECTION,
  PAGE_VIEW_LOGS_COLLECTION,
  PROPERTIES_COLLECTION,
  SKILLS_COLLECTION,
  SUBSCRIPTION_INVOICES_COLLECTION,
  SUBTASK_MESSAGES_COLLECTION,
  SUBTASKS_COLLECTION,
  TASK_MESSAGES_COLLECTION,
  TASKS_COLLECTION,
  USERS_COLLECTION,
} from '../constants/collections';
import {
  Agency,
  DraftContractorInterface,
  NoteMessages,
  SubscriptionInvoice,
} from '../interfaces';
import FileInterface from '../interfaces/file';
import InvitationInterface from '../interfaces/invitation';
import { NotificationInterface } from '../interfaces/notification';
import { Package } from '../interfaces/Package';
import PageViewLog from '../interfaces/PageViewLog';
import PropertyInterface from '../interfaces/property';
import SkillInterface from '../interfaces/skill';
import SubtaskInterface from '../interfaces/subtask';
import TaskInterface from '../interfaces/task';
import UserInterface from '../interfaces/user';
import { DataServiceClient } from './DataServiceClient';

export const packageDataService = new DataServiceClient<Package>(
  PACKAGES_COLLECTION,
);
export const taskDataService = new DataServiceClient<TaskInterface>(
  TASKS_COLLECTION,
);
export const subtaskDataService = new DataServiceClient<SubtaskInterface>(
  SUBTASKS_COLLECTION,
);
export const propertyDataService = new DataServiceClient<PropertyInterface>(
  PROPERTIES_COLLECTION,
);
export const userDataService = new DataServiceClient<UserInterface>(
  USERS_COLLECTION,
);
export const invitationDataService = new DataServiceClient<InvitationInterface>(
  INVITATIONS_COLLECTION,
);
export const notificationDataService =
  new DataServiceClient<NotificationInterface>(NOTIFICATION_COLLECTION);

export const skillDataService = new DataServiceClient<SkillInterface>(
  SKILLS_COLLECTION,
);

export const pageViewLogService = new DataServiceClient<PageViewLog>(
  PAGE_VIEW_LOGS_COLLECTION,
);

export const subscriptionInvoiceService =
  new DataServiceClient<SubscriptionInvoice>(SUBSCRIPTION_INVOICES_COLLECTION);

export const taskMessagesService = new DataServiceClient<NoteMessages>(
  TASK_MESSAGES_COLLECTION,
);

export const subtaskMessagesService = new DataServiceClient<NoteMessages>(
  SUBTASK_MESSAGES_COLLECTION,
);

export const fileService = new DataServiceClient<FileInterface>(
  FILES_COLLECTION,
);

export const draftContractorService =
  new DataServiceClient<DraftContractorInterface>(DRAFT_CONTRACTORS_COLLECTION);

export const agencyService = new DataServiceClient<Agency>(AGENCIES_COLLECTION);
