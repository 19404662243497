import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { calendarAllowedRoles, ResultData } from './CalenderTable';
import moment from 'moment';
import useCustomFunctions from '../hooks/useCustomFunctions';
import { useState } from 'react';
import useRole from '../hooks/useRole';
import Role from '../interfaces/role';
import useUID from '../hooks/useUID';

type ContextType = {
  todayJobs: ResultData[];
};

export const CalenderTodayContext = createContext<ContextType>({
  todayJobs: [],
});

export const CalenderTodayWrapper: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [fromDefault, toDefault] = useMemo(() => {
    const t = moment().startOf('day').set('hour', 0); // This will return a copy of the Date that the moment uses
    const from = t.toDate().getTime();
    const to = t.clone().endOf('day').toDate().getTime();
    return [from, to];
  }, []);
  const functions = useCustomFunctions();
  const [result, setResult] = useState<ResultData[]>([]);
  const role = useRole();
  const uid = useUID();

  useEffect(() => {
    if (!role || !uid || !calendarAllowedRoles.includes(role)) {
      return;
    }

    const fn = () => {
      functions
        .httpsCallable('calendar')({
          from: fromDefault,
          to: toDefault,
          propertyID: undefined,
          contractorID: role === Role.CONTRACTOR ? uid : undefined,
          assigneeID: undefined,
        })
        .then((resp) => {
          setResult(resp.data);
        });
    };
    fn();
    const interval = setInterval(fn, 1000 * 60 * 15);
    return () => clearInterval(interval);
  }, [fromDefault, toDefault, functions, role, uid]);

  return (
    <CalenderTodayContext.Provider value={{ todayJobs: result }}>
      {children}
    </CalenderTodayContext.Provider>
  );
};
