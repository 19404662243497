export const PACKAGES_COLLECTION = 'packages';
export const TASKS_COLLECTION = 'tasks';
export const SUBTASKS_COLLECTION = 'subtasks';
export const PROPERTIES_COLLECTION = 'properties';
export const USERS_COLLECTION = 'users';
export const SKILLS_COLLECTION = 'skills';
export const SHORT_LINKS_COLLECTION = 'shortLinks';
export const TASK_LOGS_COLLECTION = 'taskLogs';
export const TASK_PROFITS_COLLECTION = 'taskProfit';
export const SUBTASK_LOGS_COLLECTION = 'subtaskLogs';
export const PROPERTY_LOGS_COLLECTION = 'propertyLogs';
export const PAGE_VIEW_LOGS_COLLECTION = 'pageViewLogs';
export const CONTRACTOR_NOTES_COLLECITON = 'contractorNotes';
export const INVITATIONS_COLLECTION = 'invitations';
export const NOTIFICATION_COLLECTION = 'notifications';
export const SUBSCRIPTION_INVOICES_COLLECTION = 'subscriptionInvoices';
export const TASK_MESSAGES_COLLECTION = 'taskMessages';
export const SUBTASK_MESSAGES_COLLECTION = 'subtaskMessages';
export const FILES_COLLECTION = 'files';
export const DRAFT_CONTRACTORS_COLLECTION = 'draftContractors';
export const AGENCIES_COLLECTION = 'agencies';
export const USER_LOGS_COLLECTION = 'userLogs';
export const SMS_LOGS_COLLECTION = 'smsLogs'
