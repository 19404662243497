import { combineReducers, Reducer } from 'redux';
import { PopupState, PopupAction } from './popup/types';
import popupReducer from './popup/reducer';

export interface ApplicationState {
  popup: PopupState;
}

export const reducers: Reducer<ApplicationState> =
  combineReducers<ApplicationState>({
    popup: popupReducer,
  });

export type ApplicationAction = PopupAction;
