export function filterEmptiesFromObj<T>(obj: Record<string, T | null>) {
  //eslint-disable-next-line
  const valuesArr = Object.entries(obj).filter(([id, item]) => item) as [
    string,
    T,
  ][];

  const filteredValuesObj = Object.fromEntries(valuesArr);
  return filteredValuesObj;
}
