import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { useMe } from '../../hooks';
import useRole from '../../hooks/useRole';
import * as Sentry from '@sentry/browser';

export const AuthCheck: React.FC<
  PropsWithChildren<{ fallback?: ReactElement }>
> = ({ fallback, children }) => {
  const me = useMe();
  const role = useRole();

  useEffect(() => {
    if (me?.email && me.uid && role) {
      Sentry.setUser({
        email: me.email || undefined,
        id: me.uid,
        segment: role,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [me, me?.email, me?.uid, role]);

  if (!me) {
    return <>{fallback || null}</>;
  }
  return <>{children}</>;
};
