import { LinearProgress } from '@mui/material';
import React, { Suspense } from 'react';

import { CalenderTodayWrapper } from '../CalenderTodayWrapper';
import SkillsWrapper from '../SkillsWrapper';
import { AuthCheck } from './AuthCheck';
const NotLoginRouter = React.lazy(() => import('../../routers/notLoginRouter'));
const AuthRouter = React.lazy(() => import('../../routers/authRouter'));
const NotificationWrapper = React.lazy(() => import('../NotificationWrapper'));

const App: React.FC = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AuthCheck fallback={<NotLoginRouter />}>
        <SkillsWrapper>
          <CalenderTodayWrapper>
            <NotificationWrapper>
              <AuthRouter />
            </NotificationWrapper>
          </CalenderTodayWrapper>
        </SkillsWrapper>
      </AuthCheck>
    </Suspense>
  );
};
export default App;
