import { useEffect, useMemo, useState } from 'react';
import { documentId } from 'firebase/firestore';
import PropertyInterface from '../interfaces/property';
import useRole from './useRole';
import useUID from './useUID';
import Role from '../interfaces/role';
import { useFirestore } from './useFirestore';
import { getAllQueryList } from '../data/DataServiceClient';
import { propertyDataService } from '../data/services';
import { WithID } from '../interfaces';
import { filterBySubscriptionFilter, filterEmptiesFromObj } from '../helpers';
import _ from 'lodash';
interface PropertySearchInterface {
  search?: string;
  userID?: string;
  isActive?: boolean;
  id?: string;
  subscriptionFilter?: string;
}

type PropertiesListState = [Record<string, PropertyInterface>, boolean];

function useProperties({
  userID,
  search,
  isActive,
  id,
  subscriptionFilter,
}: PropertySearchInterface): PropertiesListState {
  const [unfilteredProperties, setUnfilteredProperties] = useState<
    Record<string, PropertyInterface>
  >({});
  const [loading, setLoading] = useState<boolean>(true);

  const firestore = useFirestore();
  const role = useRole();
  const uid = useUID();

  const userToFilter: string | undefined = useMemo(() => {
    if (role !== Role.ADMIN && role !== Role.MEMBER && role !== Role.ACCOUNTS) {
      if (uid !== undefined && uid.length > 0) {
        return uid;
      } else {
        return undefined;
      }
    } else {
      return userID;
    }
  }, [uid, role, userID]);

  useEffect(() => {
    if (!role || !uid) {
      return;
    }

    const queryArr: getAllQueryList<PropertyInterface> = [];

    if (userToFilter) {
      queryArr.push(['ownerID', '==', userToFilter]);
    }
    if (isActive === true || isActive === false) {
      queryArr.push(['isActive', '==', isActive]);
    }
    if (id !== undefined) {
      queryArr.push([documentId(), '==', id]);
    }

    setLoading(true);

    const listenerHelper = (
      v: Record<string, WithID<PropertyInterface> | null>,
    ) => {
      const filtered = filterEmptiesFromObj<WithID<PropertyInterface>>(v);

      setUnfilteredProperties(filtered);
      setLoading(false);
    };

    const listen = propertyDataService.listenAllData(
      firestore,
      listenerHelper,
      queryArr,
    );

    return () => {
      listen();
    };
  }, [
    firestore,
    role,
    uid,
    setUnfilteredProperties,
    userToFilter,
    isActive,
    id,
  ]);

  const properties = useMemo(() => {
    return _.pickBy(
      unfilteredProperties,
      filterBySubscriptionFilter(subscriptionFilter),
    );
  }, [subscriptionFilter, unfilteredProperties]);

  return useMemo(() => {
    if (search && search.length > 0) {
      const searchText = search.trim().toLocaleLowerCase();

      const map: Record<string, PropertyInterface> = {};
      Object.keys(properties).forEach((id) => {
        const obj = properties[id];
        const fullAddress = (
          (obj?.address?.flat || '') +
          ', ' +
          (obj?.address?.postCode || '')
        ).toLocaleLowerCase();
        if (fullAddress.indexOf(searchText) > -1) {
          map[id] = obj;
        }
      });
      return [map, loading];
    } else {
      return [properties, loading];
    }
  }, [search, properties, loading]);
}
export default useProperties;
