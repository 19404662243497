import { getDistance } from 'geolib';
import AddressInterface from '../interfaces/address';
import { meterToMile } from './meterToMile';

export const LONG_DISTANCE = 999;
export const calculateDistance = (
  a: AddressInterface | undefined | null,
  b: AddressInterface | undefined | null,
): number => {
  const lat1 = a?.lat;
  const lng1 = a?.lng;
  const lat2 = b?.lat;
  const lng2 = b?.lng;
  if (!lat1 || !lat2 || !lng1 || !lng2) {
    return LONG_DISTANCE;
  } else {
    return meterToMile(
      getDistance(
        { latitude: lat1, longitude: lng1 },
        { latitude: lat2, longitude: lng2 },
        1,
      ),
    );
  }
};
