import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import FirebaseContainer from './components/firebaseContainer';
import configureStore from './store/configureStore';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Helmet } from 'react-helmet';

const env =
  import.meta.env.VITE_VERCEL_ENV ||
  import.meta.env.REACT_APP_VERCEL_ENV ||
  'local';
Sentry.init({
  dsn: 'https://e4ff410f92864e359fd31ac8e4fcea9d@o4504366412398592.ingest.sentry.io/4504367019524096',
  integrations: [new BrowserTracing()],
  environment: env,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

console.log('Environment : ' + env);

const store = configureStore();

const rootElement: HTMLElement | null = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>On The House</title>
      </Helmet>
      <Provider store={store}>
        <BrowserRouter>
          <FirebaseContainer />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>,
  );
}
