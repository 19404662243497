import { createContext } from 'react';
import { firebaseApp } from '../components/firebaseContainer/firebaseConfig';
import AuthContextInterface from '../interfaces/AuthContextInteface';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);

const AuthContext = createContext<AuthContextInterface>({
  firestore,
  auth,
  storage,
  analytics,
  user: null,
});
export default AuthContext;
