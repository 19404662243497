import { createStore, Store } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { ApplicationState, ApplicationAction, reducers } from '.';
const composeEnhancers = composeWithDevTools({});

export default function configureStore(): Store<ApplicationState> {
  const store = createStore<ApplicationState, ApplicationAction, any, any>(
    reducers,
    undefined,
    composeEnhancers(),
  );
  return store;
}
