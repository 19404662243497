import { useContext } from 'react';
import { CalenderTodayContext } from '../components/CalenderTodayWrapper';
import { useMemo } from 'react';

function useCalenderTodayJobs(uid?: string) {
  const { todayJobs } = useContext(CalenderTodayContext);

  const results = useMemo(() => {
    let filteredList = todayJobs;
    if (uid) {
      filteredList = todayJobs.filter((job) => {
        return job.assigneeID === uid;
      });
    }

    return filteredList;
  }, [uid, todayJobs]);

  return results;
}
export default useCalenderTodayJobs;
