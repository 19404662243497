import React, { useEffect, useMemo } from 'react';
import { FormControl, TextField, Autocomplete } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SelectValueInterface from '../../interfaces/selectValue';

interface Props {
  val: string;
  values: SelectValueInterface[];
  onChange: (val: string) => void;
  defaultVal?: string;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
  showClear?: boolean;
}

const FormAutoFill: React.FC<Props> = ({
  defaultVal,
  val,
  label,
  values,
  onChange,
  disabled,
  fullWidth = true,
  style,
  className,
  showClear,
}) => {
  const id = useMemo(() => uuidv4(), []);

  useEffect(() => {
    if (defaultVal) {
      onChange(defaultVal);
    }
  }, [defaultVal, onChange]);

  const value = useMemo(() => {
    return values.find((option) => option.value === val);
  }, [values, val]);

  // if (!value) {
  //   return null;
  // }

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      margin="normal"
      key={id}
      className={className}
    >
      <Autocomplete
        key={id}
        fullWidth={fullWidth}
        disabled={disabled}
        onChange={(event: any, newValue: SelectValueInterface | null) => {
          onChange(newValue?.value || '');
        }}
        disableClearable={showClear !== true}
        value={value}
        options={values}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={label} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.key || option.label}>
              {option.label}
            </li>
          );
        }}
        style={style}
      />
    </FormControl>
  );
};
export default FormAutoFill;
