import React, { useMemo } from 'react';
import { Button, FormControl } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

interface Props {
  to: string;
  label: string;
  showBackText?: boolean;
}

const FormBack: React.FC<Props> = ({ to, label, showBackText = true }) => {
  const id = useMemo(() => uuidv4(), []);
  return (
    <FormControl margin="normal" id={id}>
      <Button
        size="small"
        startIcon={<KeyboardBackspaceIcon />}
        component={RouterLink}
        to={to}
        color="inherit"
      >
        {showBackText ? 'Back To ' : ''}
        {label}
      </Button>
    </FormControl>
  );
};
export default FormBack;
