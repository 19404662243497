import { useEffect, useMemo, useState } from 'react';
import { documentId } from 'firebase/firestore';
import { useFirestore } from './useFirestore';
import UserInterface from '../interfaces/user';
import useRole from './useRole';
import useUID from './useUID';
import AddressInterface from '../interfaces/address';
import Role from '../interfaces/role';
import { getAllQueryList } from '../data/DataServiceClient';
import { userDataService } from '../data/services';
import { WithID } from '../interfaces';
import { filterEmptiesFromObj } from '../helpers';
import { calculateDistance } from '../helpers/calculateDistance';

export type UserListInterface = Record<string, WithID<UserInterface>>;
export interface UserSearchInterface {
  search?: string;
  roles?: string[];
  isActive?: boolean;
  skillID?: string;
  address?: AddressInterface;
  excludeUsers?: string[];
  agency?: string;
}

type UserListState = [UserListInterface, boolean];

function useUsers({
  search,
  roles,
  isActive,
  skillID,
  address,
  excludeUsers,
  agency,
}: UserSearchInterface): UserListState {
  const [users, setUsers] = useState<WithID<UserInterface>[]>([]);
  const role = useRole();
  const uid = useUID();
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryArr: getAllQueryList<UserInterface> = [];

    if (
      uid &&
      role !== Role.ADMIN &&
      role !== Role.MEMBER &&
      role !== Role.ACCOUNTS
    ) {
      queryArr.push([documentId(), 'in', [uid]]);
    }

    if (roles) {
      if (roles.length === 0 || roles.length > 10) {
        return;
      }
      queryArr.push(['role', 'in', roles]);
    }

    if (skillID) {
      if (skillID.length === 0) {
        return;
      }
      queryArr.push(['skills', 'array-contains', skillID]);
    }

    if (isActive === true || isActive === false) {
      queryArr.push(['disabled', '==', !isActive]);
    }

    setLoading(true);

    const listenerHelper = (
      v: Record<string, WithID<UserInterface> | null>,
    ) => {
      const filteredData = filterEmptiesFromObj<WithID<UserInterface>>(v);
      const newList: WithID<UserInterface>[] = [];
      Object.entries(filteredData).forEach(([id, data]) => {
        if (excludeUsers && excludeUsers.indexOf(id) > -1) {
          return;
        }
        const obj = { ...data, uid: id };
        if (address) {
          obj.distance = calculateDistance(data.contactAddress, address);
        }
        if (!agency || obj.agency === agency) {
          newList.push(obj);
        }
      });
      setLoading(false);
      setUsers(newList);
    };

    const listen = userDataService.listenAllData(
      firestore,
      listenerHelper,
      queryArr,
    );

    return () => {
      listen();
    };
  }, [
    firestore,
    setUsers,
    role,
    uid,
    roles,
    isActive,
    address,
    skillID,
    excludeUsers,
    agency,
  ]);

  return useMemo(() => {
    const map: UserListInterface = {};
    if (search && search.length > 0) {
      users.forEach((user: WithID<UserInterface>) => {
        if (
          (user.displayName &&
            user.displayName.toLocaleLowerCase().indexOf(search) > -1) ||
          (user.email && user.email.toLocaleLowerCase().indexOf(search) > -1) ||
          (user.role && user.role.toLocaleLowerCase().indexOf(search) > -1) ||
          (user.uid && user.uid.toLocaleLowerCase().indexOf(search) > -1)
        ) {
          map[user.uid || ''] = user;
        }
      });
    } else {
      users.forEach((user: WithID<UserInterface>) => {
        map[user._id || ''] = user;
      });
    }
    return [map, loading];
  }, [users, search, loading]);
}
export default useUsers;
