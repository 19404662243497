import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
export const IS_EMULATOR = false;

let firebaseConfig = {
  apiKey: 'AIzaSyCFvrFxOBhfWUoeJwfK25c6-WzAbVFERP0',
  authDomain: 'on-the-house-production.firebaseapp.com',
  databaseURL: 'https://on-the-house-production.firebaseio.com',
  projectId: 'on-the-house-production',
  storageBucket: 'on-the-house-production.appspot.com',
  messagingSenderId: '504526083527',
  appId: '1:504526083527:web:3a0d175aec7747cf0175e2',
  measurementId: 'G-WT01JMEV08',
};
if (
  window.location.hostname === 'onthehouse-app.web.app' ||
  window.location.hostname === 'localhost' ||
  window.location.hostname.indexOf('vercel.app') > -1 ||
  window.location.hostname.indexOf('crm-dev.onthe.house') > -1
) {
  firebaseConfig = {
    apiKey: 'AIzaSyB3NOHzSA2Nn_CCrOQwSH6SEt63KBGDxHQ',
    authDomain: 'onthehouse-app.firebaseapp.com',
    databaseURL: 'https://onthehouse-app.firebaseio.com',
    projectId: 'onthehouse-app',
    storageBucket: 'onthehouse-app.appspot.com',
    messagingSenderId: '908949871628',
    appId: '1:908949871628:web:fc6522e064e9e91d9ee229',
    measurementId: 'G-TXY8XV8ZL3',
  };
}
const firebaseApp = initializeApp(firebaseConfig);
if (IS_EMULATOR) {
  if (window.location.hostname === 'localhost') {
    const auth = getAuth();
    connectAuthEmulator(auth, 'http://localhost:9099');
    const db = getFirestore();
    connectFirestoreEmulator(db, 'localhost', 8080);
    const storage = getStorage();
    connectStorageEmulator(storage, 'localhost', 9199);
  }
}
const analytics = getAnalytics(firebaseApp);

export default firebaseConfig;

export { firebaseApp, analytics };
