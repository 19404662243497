import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      light: '#fa584b',
      main: '#f05549',
      dark: '#d44a3f',
    },
  },
});
export default theme;
