import { TextField } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cx from 'classnames';
export interface FormBaseDateProps {
  excludeDateIntervals?: { start: Date; end: Date }[];
  val?: number | null;
  onChange?: (val: number | null) => void;
  defaultVal?: number | null;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  format: string;
  showTime?: boolean;
  filterDate?: (date: Date, print?: boolean) => boolean;
  filterTime?: (time: Date, print?: boolean) => boolean;
  timeIntervals?: number;
  formatFn?: (date: number) => string;
  minDate?: Date;
  minTime?: Date;
  maxDate?: Date;
  maxTime?: Date;
}

const FormDateBase: React.FC<FormBaseDateProps> = ({
  excludeDateIntervals,
  defaultVal,
  val,
  label,
  onChange,
  disabled,
  fullWidth = true,
  className,
  format,
  showTime,
  filterDate,
  filterTime,
  timeIntervals,
  formatFn,
  minDate,
  minTime,
  maxDate,
  maxTime,
}) => {
  const [prevDefault, setPrevDefault] = useState<number | null | undefined>(
    null,
  );
  useEffect(() => {
    if (defaultVal !== prevDefault && defaultVal !== undefined && onChange) {
      onChange(defaultVal);
      setPrevDefault(defaultVal);
    }
  }, [defaultVal, onChange, prevDefault, setPrevDefault]);
  const textValue = useMemo(() => {
    if (!val) {
      return null;
    }
    if (formatFn) {
      return formatFn(val);
    }
    return moment(val).format(format);
  }, [format, formatFn, val]);
  return (
    <div className={cx({ 'w-full': fullWidth }, className)}>
      <DatePicker
        excludeDateIntervals={excludeDateIntervals}
        timeIntervals={timeIntervals}
        filterDate={filterDate}
        filterTime={filterTime}
        minTime={minTime}
        maxTime={maxTime}
        minDate={minDate}
        maxDate={maxDate}
        popperClassName="!z-50"
        disabled={disabled}
        showTimeSelect={showTime}
        selected={val === null || val === undefined ? val : new Date(val)}
        onChange={(date) => {
          const filterDateCheck =
            filterDate && date ? filterDate(date, true) : true;

          const filterTimeCheck =
            filterTime && date ? filterTime(date, true) : true;

          const minDateCheck =
            minDate && date ? minDate.getTime() <= date.getTime() : true;

          const maxDateCheck =
            maxDate && date ? maxDate.getTime() >= date.getTime() : true;

          const minTimeCheck =
            minTime && date
              ? moment(date)
                  .set('minute', minTime.getMinutes())
                  .set('hour', minTime.getHours())
                  .isSameOrBefore(date, 'minutes')
              : true;

          const maxTimeCheck =
            maxTime && date
              ? moment(date)
                  .set('minute', maxTime.getMinutes())
                  .set('hour', maxTime.getHours())
                  .isSameOrAfter(date, 'minutes')
              : true;

          console.log({
            filterDateCheck,
            filterTimeCheck,
            minDateCheck,
            maxDateCheck,
            minTimeCheck,
            maxTimeCheck,
          });

          // if (
          //   !filterDateCheck ||
          //   !filterTimeCheck ||
          //   !minDateCheck ||
          //   !maxDateCheck ||
          //   !minTimeCheck ||
          //   !maxTimeCheck
          // ) {
          //   return;
          // }
          if (onChange) {
            onChange(date ? date.getTime() : null);
          }
        }}
        // dateFormat={format}
        customInput={
          <div>
            <TextField
              onChange={() => {}}
              label={label}
              disabled={disabled}
              margin="normal"
              variant="outlined"
              className={cx('w-full mt-4 mb-2')}
              value={textValue || ''}
              inputProps={{
                autoComplete: 'off',
                'data-lpignore': 'true',
              }}
            />
          </div>
        }
      />
    </div>
  );
};
export default FormDateBase;
