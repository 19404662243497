import { useEffect, useState } from 'react';
import { useFirestore } from './useFirestore';
import {
  DataServiceClient,
  getAllQueryList,
  queryOrderBy,
} from '../data/DataServiceClient';
import { WithID } from '../interfaces';

function useAllData<T>(
  collection: string,
  queryList?: getAllQueryList<T>,
  orderBy?: queryOrderBy<T>,
  limit?: number,
) {
  const firestore = useFirestore();
  const [map, setMap] = useState<Record<string, WithID<T> | null>>();

  useEffect(() => {
    const service = new DataServiceClient<T>(collection);
    return service.listenAllData(firestore, setMap, queryList, orderBy, limit);
  }, [firestore, collection, queryList, orderBy, limit]);

  return map;
}
export default useAllData;
