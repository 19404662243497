import type { Firestore } from 'firebase/firestore';
import { pageViewLogService } from '../data/services';
const setPageView = async (
  firestore: Firestore,
  uid: string,
  pageUrl: string,
): Promise<void | any> => {
  pageViewLogService.addData(firestore, { uid, pageUrl });
};

export default setPageView;
