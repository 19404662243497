import {
  IS_EMULATOR,
  firebaseApp,
} from '../components/firebaseContainer/firebaseConfig';
import {
  getFunctions,
  Functions,
  httpsCallable,
  connectFunctionsEmulator,
} from 'firebase/functions';
import { useMemo } from 'react';
import { getApp } from 'firebase/app';

class CustomFunction {
  private obj: Functions;
  constructor(customFunctions: Functions) {
    this.obj = customFunctions;
  }
  httpsCallable(name: string) {
    return (data: any) => {
      return httpsCallable<any, any>(this.obj, name)(data);
    };
  }
}

const useCustomFunctions = () => {
  return useMemo(() => new CustomFunction(getFunctionsObj()), []);
};
const getFunctionsObj = () => {
  if (IS_EMULATOR) {
    if (window.location.hostname === 'localhost') {
      const functions = getFunctions(getApp());
      connectFunctionsEmulator(functions, 'localhost', 5001);
      return getFunctions();
    } else {
      return getFunctions(firebaseApp, 'europe-west2');
    }
  } else {
    return getFunctions(firebaseApp, 'europe-west2');
  }
};

export default useCustomFunctions;
