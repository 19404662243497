import { useContext } from 'react';
import AuthContext from '../context/AuthContext';
import Role from '../interfaces/role';

function useRole(): Role | undefined {
  const { role } = useContext(AuthContext);
  return role;
}

export default useRole;
