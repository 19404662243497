import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { setCurrentScreen, logEvent } from 'firebase/analytics';
import { useFirestore } from '../../hooks';
import useUID from '../../hooks/useUID';
import setPageView from '../../api/setPageView';
import { useAnalytics } from '../../hooks/useAnalytics';

function FirebaseAnalytics() {
  const firestore = useFirestore();
  const location = useLocation();
  const analytics = useAnalytics();
  const uid = useUID();
  useEffect(() => {
    const page_path = location.pathname + location.search;
    setCurrentScreen(analytics, page_path);
    logEvent(analytics, 'page_view', { page_path });
  }, [analytics, location]);

  useEffect(() => {
    console.info('Analytics on location change event', uid, location);
    if (uid && location && firestore) {
      setPageView(firestore, uid, location.pathname);
    }
  }, [uid, location, firestore]);
  return null;
}
export default FirebaseAnalytics;
