import {
  FieldPath,
  WhereFilterOp,
  Firestore,
  QuerySnapshot,
  CollectionReference,
  Query,
  DocumentReference,
  DocumentData,
  getDocs,
  limit,
  setDoc,
  addDoc,
  QueryDocumentSnapshot,
  SnapshotOptions,
  getCountFromServer,
} from 'firebase/firestore';
import {
  documentId,
  collection,
  doc,
  getDoc,
  onSnapshot,
  where,
  orderBy,
  query,
} from 'firebase/firestore';
import _, { List, uniq, chunk } from 'lodash';
import { WithID } from '../interfaces';

export type getAllQueryList<T> = [
  keyof T | FieldPath | 'do_not_query',
  WhereFilterOp,
  string | boolean | number | string[],
][];
export type queryOrderBy<T> = [keyof T, 'desc' | 'asc'];

export class DataServiceClient<T> {
  collection: string;
  constructor(collection: string) {
    this.collection = collection;
  }
  private converter = {
    toFirestore(val: Partial<T>): DocumentData {
      return val;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): Partial<T> {
      return snapshot.data(options) as Partial<T>;
    },
  };
  private getCollection = (firestore: Firestore | undefined) => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    return collection(firestore, this.collection).withConverter<Partial<T>>(
      this.converter,
    );
  };
  private getDoc = (firestore: Firestore | undefined, id: string) => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    return doc(this.getCollection(firestore), id);
  };
  getData = async (
    firestore: Firestore | undefined,
    id?: string | null,
  ): Promise<WithID<T> | null> => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    if (!id) {
      return null;
    }
    const docRef = this.getDoc(firestore, id);
    const docSnap = await getDoc(docRef);
    if (!docSnap || !docSnap.exists) {
      return null;
    }
    const data = docSnap.data() as T | null;
    if (!data) {
      return null;
    }
    return { ...data, _id: id };
  };
  listenData = (
    firestore: Firestore | undefined,
    listen: (v: WithID<T> | null) => void,
    id?: string | null,
  ): (() => void) => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    if (!id) {
      return () => {};
    }
    const docRef = this.getDoc(firestore, id);
    return onSnapshot(docRef, (docSnap) => {
      if (!docSnap || !docSnap.exists) {
        return listen(null);
      }
      const data = docSnap.data() as T | null;
      if (!data) {
        return listen(null);
      }
      return listen({ ...data, _id: id });
    });
  };
  onSnap = (snapshot: QuerySnapshot) => {
    const map: Record<string, WithID<T>> = {};
    snapshot.forEach((doc) => {
      const data: T = doc.data() as T;
      map[doc.id] = { _id: doc.id, ...data };
    });
    return map;
  };
  getAllData = async (
    firestore: Firestore | undefined,
    queryList?: getAllQueryList<T>,
    orderByParam?: queryOrderBy<T>,
    limitParam?: number,
  ): Promise<Record<string, WithID<T> | null>> => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    let collectionRef: CollectionReference | Query =
      this.getCollection(firestore);
    if (queryList) {
      queryList.forEach((q) => {
        collectionRef = query(collectionRef, where(q[0] as string, q[1], q[2]));
      });
    }
    if (orderByParam) {
      if (limitParam) {
        collectionRef = query(
          collectionRef,
          orderBy(orderByParam[0] as string, orderByParam[1]),
          limit(limitParam),
        );
      } else {
        collectionRef = query(
          collectionRef,
          orderBy(orderByParam[0] as string, orderByParam[1]),
        );
      }
    }

    try {
      const snapshot = await getDocs(collectionRef);
      return this.onSnap(snapshot);
    } catch (err) {
      console.log('GET ERROR', err);
    }
    return {};
  };
  count = async (
    firestore: Firestore | undefined,
    queryList?: getAllQueryList<T>,
  ): Promise<number> => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    let collectionRef: CollectionReference | Query =
      this.getCollection(firestore);
    if (queryList) {
      queryList.forEach((q) => {
        collectionRef = query(collectionRef, where(q[0] as string, q[1], q[2]));
      });
    }

    try {
      const countShot = await getCountFromServer(collectionRef);
      return countShot.data().count;
    } catch (err) {
      console.log('GET ERROR', err);
    }
    return 0;
  };
  listenAllData = (
    firestore: Firestore | undefined,
    listen: (map: Record<string, WithID<T> | null>) => void,
    queryList?: getAllQueryList<T>,
    orderByParam?: queryOrderBy<T>,
    limitParam?: number,
  ): (() => void) => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    let collectionRef: CollectionReference | Query =
      this.getCollection(firestore);
    if (queryList) {
      for (let i = 0; i < queryList.length; i++) {
        const q = queryList[i];
        if (q[0] === 'do_not_query') {
          listen({});
          return () => {};
        }
        collectionRef = query(collectionRef, where(q[0] as string, q[1], q[2]));
      }
    }
    if (orderByParam) {
      if (limitParam) {
        collectionRef = query(
          collectionRef,
          orderBy(orderByParam[0] as string, orderByParam[1]),
          limit(limitParam),
        );
      } else {
        collectionRef = query(
          collectionRef,
          orderBy(orderByParam[0] as string, orderByParam[1]),
        );
      }
    }

    return onSnapshot(collectionRef, (snapshot) => {
      listen(this.onSnap(snapshot));
    });
  };
  updateData = async (
    firestore: Firestore | undefined,
    id: string | null,
    obj: Partial<T>,
  ): Promise<void> => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    if (!id) {
      return;
    }
    const docRef = this.getDoc(firestore, id);
    await setDoc(docRef, obj, { merge: true });
  };
  addData = async (
    firestore: Firestore | undefined,
    obj: Partial<T>,
    customId?: string,
  ): Promise<void | DocumentReference<DocumentData>> => {
    if (!firestore) {
      throw new Error('Firestore is not defined');
    }
    const collectionRef = this.getCollection(firestore);
    if (customId) {
      return this.updateData(firestore, customId, obj);
    } else {
      return await addDoc<Partial<T>, DocumentData>(collectionRef, obj);
    }
  };
  getIDList = async (
    firestore: Firestore | undefined,
    idList: List<string | undefined>,
  ): Promise<Record<string, WithID<T>>> => {
    const uniques = uniq(idList);
    const cleanList: string[] = [];
    uniques.forEach((item) => {
      if (item) {
        cleanList.push(item);
      }
    });

    const chunks = chunk<string>(cleanList, 10);
    const promises = chunks.map((ids) =>
      this.getAllData(firestore, [[documentId(), 'in', ids]]),
    );
    return Promise.all(promises).then((maps) => {
      const cleanedMap: Record<string, WithID<T>> = {};
      maps.forEach((map) => {
        _.toPairs(map).forEach(([id, obj]) => {
          if (obj) {
            cleanedMap[id] = obj;
          }
        });
      });

      return cleanedMap;
    });
  };
  listenIDList = (
    firestore: Firestore | undefined,
    onChange: (id: string, obj: WithID<T> | null) => void,
    idList: List<string | undefined>,
  ): (() => void) => {
    const uniques = uniq(idList);
    const cleanList: string[] = [];
    uniques.forEach((item) => {
      if (item) {
        cleanList.push(item);
      }
    });

    const chunks = chunk<string>(cleanList, 10);
    const listeners = chunks.map((ids) => {
      return this.listenAllData(
        firestore,
        (map) => {
          _.toPairs(map).forEach(([id, obj]) => {
            onChange(id, obj);
          });
        },
        [[documentId(), 'in', ids]],
      );
    });
    return () => {
      listeners.map((l) => l());
    };
  };
}
