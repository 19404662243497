import { Reducer } from 'redux';
import { ActionEnum, PopupState, PopupAction, SetPopupAction } from './types';

const initialState = (): PopupState => ({ arr: [] });

const reducer: Reducer<PopupState, PopupAction> = (
  state: PopupState = initialState(),
  action: PopupAction,
) => {
  switch ((action as PopupAction).type) {
    case ActionEnum.SET_POPUP: {
      const { type, args } = (action as SetPopupAction).args;
      return { ...state, arr: [...state.arr, { type: type, args: args }] };
    }
    case ActionEnum.REMOVE_POPUP: {
      const newArr = [...state.arr];
      newArr.pop();
      return { ...state, arr: newArr };
    }
    case ActionEnum.REMOVE_ALL_POPUP: {
      return { ...state, arr: [] };
    }
    default:
      return state;
  }
};

export default reducer;
